<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import Calculation from "./nodes/Calcutation.svelte";
  import Invoice from "./nodes/Invoice.svelte";
  import Offer from "./nodes/Offer.svelte";

  export const cusId = null;

  const today = new Date().toISOString().split("T")[0];

  let state = {
    invDate: today,
    invVal: null,
    invNo: null,
    rzFirstDay: "01",
    rzPeriods: 6,
    debFname: null,
    debLname: null,
    debEmail: null,
    debStreet: null,
    debHouseNumber: null,
    debPlz: null,
    debCity: null,
    debPhone: null,
    cusName: null,
    cusLoc: null,
    cusId: null,
  };
  const steps = ["calc", "invoice", "offer"];

  let currentStepIndex = 0;
  let isMounted = false;

  let isFinished = false;

  let functions = {};

  let config = undefined;
  let root = null;
  let imagePath = null;

  onMount(() => {
    isMounted = true;
	window.dataLayer = window.dataLayer || []
    if (window.cusId) {
      state.cusId = window.cusId;
    }
    if (adeltaRoot) {
      root = adeltaRoot;
      imagePath = adeltaRoot + "/images";
    }
    maybeInit();
  });

  function maybeInit() {
    if (isMounted) {
      functions = {
        init: adeltaApplyRazInit,
        calc: adeltaApplyRazCalc,
        periods: adeltaApplyRazPeriods,
        send: adeltaApplyRazSend,
      };
      adeltaApplyRazInit();

      const setConfig = () => {
        if (window.adeRaz.ready) {
          config = window.adeRaz.conditions;
        } else {
          setTimeout(setConfig, 25);
        }
      };
      setTimeout(setConfig, 25);
    }
  }

  $: isFirst = currentStepIndex === 0;
  $: isLast = currentStepIndex === steps.length - 1;
  $: currentStep = steps[currentStepIndex];

  $: isCalc = currentStep === "calc";
  $: isInvoice = currentStep === "invoice";
  $: isOffer = currentStep === "offer";

  function scrollToTop() {
    const a = document.getElementById("adelta-finanzrechner");
    const bounds = a.getBoundingClientRect();
    const top = document.documentElement.scrollTop;
    window.scrollTo(0, top + bounds.top);
  }

  function gotoNext($event) {
    state = $event.detail;
    currentStepIndex = isLast ? currentStepIndex : currentStepIndex + 1;
    scrollToTop();
  }

  function gotoPrev($event) {
    state = $event.detail;
    currentStepIndex = isFirst ? currentStepIndex : currentStepIndex - 1;
    scrollToTop();
  }

  function onFinished($event) {
    currentStep;
    isFinished = true;
	window.dataLayer.push({
    	'event': 'form_ratenrechner',
    	'step': 'form_success'
	});
	
    scrollToTop();
  }
</script>

<main
  class="text-lg font-sans font-light rounded-xl center p-12 my-0 mx-auto max-w-40 md:max-w-none bg-white font-base"
>
  <div
    id="steps"
    class="relative mb-8 w-full flex flex-row justify-center align-center flex-nowrap gap-2"
  >
    {#each steps as _, index}
      <div
        class="h-0.5 w-8 bg-gray-300"
        class:bg-brand={index === currentStepIndex}
      />
    {/each}
  </div>
  {#if isFinished}
    <div class="">
      <h3 class="text-2xl text-brand font-extrabold mb-2">Vielen Dank!</h3>
      <h4 class="text-brand font-light mb-4">für Ihre Anfrage bei uns</h4>
      <p>Wir melden uns bald bei Ihnen.</p>
    </div>
  {:else}
    <div class="flex flex-row flex-nowrap w-full ">
      <div
        class="bg-white w-full mt-8 transition {isCalc
          ? 'opacity-100 pointer-events-all scale-100'
          : 'absolute scale-0 w-[calc(100%-6rem)] scale-0 opacity-0 pointer-events-none'}"
      >
        <Calculation {state} on:next={gotoNext} fs={functions} />
      </div>
      <div
        class="bg-white w-full mt-8 transition  {isInvoice
          ? 'opacity-100 pointer-events-all scale-100'
          : 'absolute scale-0 w-[calc(100%-6rem)] scale-0 opacity-0 pointer-events-none'}"
      >
        <Invoice {state} on:next={gotoNext} on:prev={gotoPrev} />
      </div>
      <div
        class="bg-white w-full mt-8 transition {isOffer
          ? 'opacity-100 pointer-event-all scale-100'
          : 'absolute scale-0 w-[calc(100%-6rem)] opacity-0 pointer-events-none '}"
      >
        <Offer
          {state}
          {config}
          on:next={onFinished}
          on:prev={gotoPrev}
          fs={functions}
        />
      </div>
    </div>
  {/if}
</main>

<style lang="postcss" global>
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
  }

  @font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
  }

  @font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
  }

  #adelta-finanzrechner *,
  #adelta-finanzrechner ::before,
  #adelta-finanzrechner ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }

  #adelta-finanzrechner {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: Roboto, Helvetica, Arial, Lucida, sans-serif;
  }

  #adelta-finanzrechner textarea {
    resize: vertical;
  }

  #adelta-finanzrechner input::-moz-placeholder,
  #adelta-finanzrechner textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af;
  }

  #adelta-finanzrechner input:-ms-input-placeholder,
  #adelta-finanzrechner textarea:-ms-input-placeholder {
    opacity: 1;
    color: #9ca3af;
  }

  #adelta-finanzrechner input::placeholder,
  #adelta-finanzrechner textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
  }

  main {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }

  #adelta-finanzrechner button,
  #adelta-finanzrechner input,
  #adelta-finanzrechner optgroup,
  #adelta-finanzrechner select,
  #adelta-finanzrechner textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }

  #adelta-finanzrechner button,
  #adelta-finanzrechner select {
    text-transform: none;
  }

  #adelta-finanzrechner button,
  #adelta-finanzrechner [type="button"],
  #adelta-finanzrechner [type="reset"],
  #adelta-finanzrechner [type="submit"] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }

  @tailwind components;
  @tailwind utilities;

  main {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }
</style>
