<script>
 import { onMount, createEventDispatcher } from 'svelte';
 import RangeSlider from "svelte-range-slider-pips";

 export let state

 const today = new Date()
 const max = today.toISOString().split("T")[0]
 const days = [
	 {
		 val: "01",
		 desc: "am 1. des Monats"
	 },
	 {
		 val: "15",
		 desc: "am 15. des Monats"
 }]

 let invDate = state.invDate ?? max

 let invNo = state.invNo ?? null
 let rzFirstDay = state.rzFirstDay ?? days[0].val
 let checkForInvalid = false

 const dispatch = createEventDispatcher();

 $: is01 = rzFirstDay === "01"
 $: is15 = rzFirstDay === "15"
 $: forbidNext = !invNo ? true : null
 $: isError = !invNo && checkForInvalid ? true : false

 $: isPristine = checkForInvalid ? false : true
 $: isCorrectlyFilledInvNo = invNo && checkForInvalid ? true : false
 $: isCorrectlyFilledInvDate = invDate && checkForInvalid ? true : false
 $: isCorrectlyFilledRzFirstDay = rzFirstDay && checkForInvalid ? true : false
 
 function focusMe(node, isError) {
	 return {
		 update(isError) {
			 if (isError === true) {
				 node.focus()
			 }
		 }
	 }
 }

 function updateState() {
	 const s = JSON.parse(JSON.stringify(state))
	 s.invDate = invDate
	 s.invNo = invNo
	 s.rzFirstDay = rzFirstDay
	 return s
 }

 function gotoNext($event) {
	 checkForInvalid = true
	 if (forbidNext) {
		 return 
	 }
	 else {
		window.dataLayer.push({
    		'event': 'form_ratenrechner',
    		'step': 'two'
		});
		 dispatch("next", updateState())
	 }
 }

 function gotoPrev($event) {
	 dispatch("prev", updateState())
 }
</script>
<div class="value flex flex-col">

	<div class="w-full mb-4">Bitte füllen Sie die Felder aus</div>
	<label for="invNo" class="text-xl text-brand font-extrabold">Rechnungsnummer</label>
		<input type="text" 
			   id="invNo"
			   placeholder="Rechnungsnummer"
			   use:focusMe={ isError }
			   class="font-sans select-all block rounded mt-4 w-full h-12 p-6 border border-l-[3px] border-gray-200  outline-2
					 { isError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
					 { isPristine ? 'border-l-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
					 { isCorrectlyFilledInvNo ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
			   bind:value={ invNo } />
</div>
<div class="mt-8 flex flex-col">
	<label for="invDate" class="text-xl text-brand font-extrabold">Rechnungsdatum</label>
	<input type="date" { max } 
		   id="invDate" placholder="Pflichtfeld"
			   class="font-sans bg-white block rounded mt-4 w-full h-12 px-6 py-3 outline-2 focus:outline-brand leading-none border border-l-[3px] 
				 { isPristine ? 'border-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
				 { isCorrectlyFilledInvDate ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
				 bind:value={ invDate } />
</div>
<div class="mt-8 flex flex-col">
	<label for="rzFirstDay" class="text-xl text-brand font-extrabold">Einzug der Raten</label>
	<select id="rzFirstDay" name="rzFirstDay"
			bind:value={ rzFirstDay }
			class="font-sans outline-none bg-white block rounded mt-4 w-full h-12 p-2 border border-l-[3px] outline-2 focus:outline-brand
			active:border-gray-400
			{ isPristine ? 'border-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
			{ isCorrectlyFilledRzFirstDay ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }">
		{#each days as day}
			<option value={ day.val } >{ day.desc }</option>
		{/each}
	</select>
</div>
<div class="flex flex-row justify-center gap-x-2">
	<a href="#prev"
	   class="inline-block border border-brand mt-8 px-8 py-2 rounded bg-white text-brand no-underline font-medium transition-all translate-y-0
			 hover:-translate-y-px hover:drop-shadow"
	   on:click|preventDefault={ gotoPrev }
	>Zurück</a>
	<input type="button"
	   class="inline-block mt-8 px-8 py-2 rounded bg-brand text-white font-medium no-underline transition-all cursor-pointer
			 translate-y-0 hover:-translate-y-px hover:drop-shadow disabled:opacity-50 disabled:pointer-events-none"
	   on:click|preventDefault={ gotoNext } value="Weiter" />
</div>

