<script>
 import { onMount, onDestroy, createEventDispatcher } from 'svelte';
 import RangeSlider from "svelte-range-slider-pips";

 export let state
 export let fs

 const dispatch = createEventDispatcher()

 let values = [0]
 let invVal = state.invVal ? state.invVal : null
 let checkForInvalid = false

 const today = (new Date()).toISOString().split("T")[0]

 $: invDisplay = invVal ? invVal.toLocaleString("de-DE", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""
 $: lastIndex =  periods ? periods.length - 1 : 0

 $: selectedIndex = values[0]
 $: labelList = periods.map(c => c + " Monate")
 $: valueList = periods.map(c => c)
 $: label = labelList[selectedIndex]
 $: value = valueList[selectedIndex]

 $: init = fs.init
 $: calc = fs.calc
 $: periodsRaw = fs.periods ? fs.periods(false) : [6]
 $: periods = periodsRaw ?? [6]

 $: rate = fs.calc ? fs.calc(value, invVal, today, "01") : undefined
 $: instValue = rate ? rate.instValue : undefined
 $: rateGerman = instValue ? instValue.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) : undefined

 $: first = valueList[0]
 $: last = valueList[labelList.length - 1]

 $: forbidNext = invVal ? null : true

 $: isError = !invVal && checkForInvalid ? true : false
 $: isCorrectlyFilled = invVal && checkForInvalid ? true : false
 $: isPristine = checkForInvalid ? false : true

 const min = 0

 onMount(() => {
	 if (periods && state.rzPeriods) {
		 values = Array.of(periods.findIndex(p => p === state.rzPeriods))
	 }
	 else {
		 values = [0]
	 }

	 
 })

 function focusMe(node, isError) {
	 return {
		 update(isError) {
			 if (isError === true) {
				 node.focus()
			 }
		 }
	 }
 }

 function sanitize(event) {
	 if (event instanceof InputEvent) {
		 const allowedInputTypes = ['deleteContentBackward', 'deleteWordBackward', 'deleteSoftLineBackward',
									'deleteEntireSoftLine', 'deleteHardLineBackward', 'deleteHardLineForward',
									'deleteContent',
									'deleteContentForward', 'deleteWordForward', 'deleteSoftLineForward',
									'deleteByCut', 'deleteByDrag',
									'historyUndo', 'historyRedo']
		 if (allowedInputTypes.includes(event.inputType)) {
			 return true
		 }
		 
		 const allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
		 if (allowed.includes(event.data)) {
			 // 0 1 2 3 4 5 6 7 8 9
			 // haben wir schon ein komma?
			 const commapos = event.currentTarget.value.search(",");
			 // wenn wir eins haben
			 if (commapos > 0) {
				 // sind wir schon nach dem komma?
				 if (commapos < event.currentTarget.selectionStart) {
					 // haben wir schon 2 stellen nach dem komma
					 if(((event.currentTarget.value.length - commapos) > 2) && ((selLength) == 0)) {
						 event.preventDefault()
						 return false;
					 }
				 }
			 }
			 return true;
		 }

		 // . werden zwar angenommen haben aber erst einmal keinerlei auswirkungen
		 if (event.data === '.') { return true }
		 // , darf nur ein mal vorkommen
		 if (event.data === ",") { 
			if (event.currentTarget.value.includes(',')) {
				event.preventDefault()
				return false;
			}
			return true
		 }
		 event.preventDefault()
		 return false;
	 }
	 return false

	 /*
	 // was wurde gedrückt
	 const code = event.code;
     // was steht derzeit im feld
     const value = event.currentTarget.value;
     // wo steht der cursor
     const selLength = event.currentTarget.selectionEnd - event.currentTarget.selectionStart;

	 const allowed = ["Period", "Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"]
	 const numbers = [...Array(10).keys()]
	 const prefixes = ["Numpad", "Digit"]
	 const allowedNumbers = prefixes.reduce((acc, current) => {
		 const prefixed = numbers.map(x => current + x)
		 return acc.concat(prefixed)
	 }, []).concat("NumpadDecimal")
     // wurde ein zahl eingegeben
     if (allowedNumbers.includes(code) || numbers.includes(event.data)) {
		 // 0 1 2 3 4 5 6 7 8 9
		 // haben wir schon ein komma?
		 const commapos = value.search(",");
		 // wenn wir eins haben
		 if (commapos > 0) {
			 // sind wir schon nach dem komma?
			 if (commapos < event.currentTarget.selectionStart) {
				 // haben wir schon 2 stellen nach dem komma
				 if(((value.length - commapos) > 2) && ((selLength) == 0)) {
					 event.preventDefault()
					 return false;
				 }
			 }
		 }
		 return true;
	 }
     // . werden zwar angenommen haben aber erst einmal keinerlei auswirkungen
     if (allowed.includes(code)) return true;
     // , darf nur ein mal vorkommen
     if (code === "Comma"){ 
         if(event.currentTarget.value.includes(',')) {
			 event.preventDefault()
			 return false;
		 }
         return true
     }
	 event.preventDefault()
     return false;
	 */

 }

 function format(event) {
	 // formatierer erstellen, locale undefined nutzt default local des browsers
     const formatter = new Intl.NumberFormat("de-DE", {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
     let value = event.currentTarget.value;
     if (value == ''){
         value = 0;
     } else {
         // alle Punkte raus
         value = value.replace(/\./g, "");
         // komma zu punkt
         value = value.replace(/,/g, ".");
         if (value != '') {
             value = parseFloat(value);
			 if (isNaN(value)) {
				 value = null
			 }
         } else {
             value = 0;
         }
     }
	 invVal = value ?? null 
     // formatierten wert zurück ins feld
     event.currentTarget.value = formatter.format(value);

 }


 function gotoNext($event) {
	 checkForInvalid = true
	 if (forbidNext) {
		 return
	 }
	 else {
		 const s = JSON.parse(JSON.stringify(state))
		 s.invVal = invVal
		 s.rzPeriods = value
		 window.dataLayer.push({
    		'event': 'form_ratenrechner',
    		'step': 'one'
		});
		 dispatch("next", s)
	 }
 }
</script>
<div class="value flex flex-col">
	<div class="w-full mb-4">Bitte füllen Sie die Felder aus</div>
	<label for="invValue" class="text-xl text-brand font-extrabold">Rechnungsbetrag</label>
	<label for="invValue" class="font-light">Bitte geben Sie den gesamten Betrag an</label>
	<div class="grid grid-cols-[1fr,auto] gap-4">
		<input type="text" 
			   id="invValue"
			   inputmode="decimal"
			   placeholder="Rechnungsbetrag"
			   on:beforeinput={ sanitize }
			   on:change={ format }
			   use:focusMe={ isError }
			   class="font-sans transition block rounded mt-4 w-full h-12 p-6 border border-l-[3px] border-gray-200 outline-2
			   { isError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
			   { isPristine ? 'border-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
			   { isCorrectlyFilled ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
			   value={ invDisplay } />
		<span class="mt-4 flex items-center justify-center text-xl ">€</span>
	</div>
</div>
<div class="slider mt-8 flex flex-col">
	<span class="text-xl text-brand font-extrabold">Laufzeit</span>
	<span class="">Bitte bewegen Sie den Schieberegler</span>
	<span class="mt-8 font-medium w-full text-center">{ label }</span>
	<div class="w-full grid grid-cols-[auto,1fr,auto] gap-x-2"
		 style="--range-handle-focus: rgb(0 134 203); --range-handle: rgb(0 134 203); --range-handle-inactive:rgb(5 74 124); --range-slider: #E7E5E4; --range-range: #92400E;">
		<span class="first block pt-2">{ first }</span>
		<RangeSlider bind:values
					 min="{ min }"
					 pips="{ true }"
					 max="{ lastIndex }"
					 formatter={ v => labelList[v]} />
		<span class="last block pt-2">{ last }</span>
	</div>
</div>
{#if instValue }
	<div class="text-center">Monatliche Rate: <span class="font-medium">{ rateGerman }</span></div>
{/if}
<div class="flex flex-row justify-center">
	<input type="button"
		   class="inline-block mt-12 px-8 py-2 no-underline rounded bg-brand text-white rounded font-medium transition-all
				 translate-y-0  cursor-pointer
				 hover:-translate-y-px hover:drop-shadow" on:click|preventDefault={ gotoNext } value="Weiter" />
</div>

