<script>
 import { onMount, createEventDispatcher } from 'svelte';
 import RangeSlider from "svelte-range-slider-pips";

 export let state
 export let fs
 export let config

 const dispatch = createEventDispatcher();
 const today = (new Date).toISOString().split("T")[0]

 let imagePath = ""

 let debFname = null
 let debLname = null
 let debEmail = null
 let debStreet = null
 let debPlz = null
 let debCity = null
 let debPhone = null
 let cusName = null
 let cusLoc = null

 let isBusy = false
 let isTooLong = false

 let checkForInvalid = false

 $: allItems = [
	 {
		 name: "debFname",
		 label: "Vorname",
		 value: debFname
	 }, {
		 name: "debLname",
		 label: "Name",
		 value:debLname
	 }, {
		 name: "debStreet",
		 label: "Straße und Hausnummer",
		 value: debStreet
	 }, {
		 name: "debPlz",
		 label: "PLZ",
		 value: debPlz
	 }, {
		 name: "debCity",
		 label: "Ort",
		 value: debCity
	 }, {
		 name: "debPhone",
		 label: "Telefon",
		 value: debPhone
	 }, { 
		name: "debEmail",
		label: "E-Mail",
		value: debEmail
	}
 ]

 
 $: needsCustomer = state.cusId === null
 $: items = needsCustomer ? allItems
	 .filter(i => i.name !== "cusName")
	 .filter(i => i.name !== "cusLoc")
	 .concat({
		 name: "cusName",
		 label: "Rechnungs ausgestellt durch",
		 value: cusName
	 }, {
		 name: "cusLoc",
		 label: "Ort des Rechnungsaustellers",
		 value: cusLoc
	 }) : allItems
 
 $: all = items.map(i => i.value)
 $: unfilled = items.filter(i => (i.value === null || i.value === false || i.value === ''))
 $: hasEmpty = unfilled.length > 0

 $: hasCustomer = state.cusId  || (cusName && cusLoc)

 $: external = state.cusId ? "unseren Finanzdienstleister, " : ""

 $: forbidSend = (!hasCustomer || all.some(x => x === null || x === false || x === '')) ? true : null
 $: isError = forbidSend && checkForInvalid ? true : false
 $: isFnameError = isError && !debFname
 $: isLnameError = isError && !debLname
 $: isStreetError = isError && !debStreet
 $: isPlzError = isError && !debPlz
 $: isCityError = isError && !debCity
 $: isPhoneError = isError && !debPhone
 $: isEmailError = isError && !debEmail
 $: isCusNameError = isError && !cusName
 $: isCusLocError = isError && !cusLoc


 $: isPristine = checkForInvalid ? false : true
 $: isFnameCorrect = checkForInvalid && debFname
 $: isLnameCorrect = checkForInvalid && debLname
 $: isStreetCorrect = checkForInvalid && debStreet
 $: isPlzCorrect = checkForInvalid && debPlz
 $: isCityCorrect = checkForInvalid && debCity
 $: isPhoneCorrect = checkForInvalid && debPhone
 $: isEmailCorrect = checkForInvalid && debEmail
 $: isCusNameCorrect = checkForInvalid && cusName
 $: isCusLocCorrect = checkForInvalid && cusLoc

 $: initResult = fs.calc ? fs.calc(state.rzPeriods, state.invVal, state.invDate, state.rzFirstDay)  : undefined

 $: overdue = initResult ? initResult.overDue : false

 $: dueMonths = overdue && config ? Object.keys(config.due).map(d => config.due[d].months) : []
 $: results = fs.calc ?
			  dueMonths.map(m => fs.calc(m, state.invVal, state.invDate, state.rzFirstDay)) : []

 $: working = results.filter(r => r.success)
 $: workingMonths = working.map(w => w.periods)
 $: firstWorkingMonth = workingMonths.length > 0 ? workingMonths[0] : undefined
 $: areMonthsChanged = firstWorkingMonth !== undefined && firstWorkingMonth.toString(10) !== state.rzPeriods
 $: finalResult = areMonthsChanged ? working[0] : initResult
 
 $: success = finalResult ? finalResult.success : false
 $: errors = finalResult && !success ? finalResult.errors : []

 $: instValue = finalResult ? finalResult.instValue : undefined
 $: rateGerman = instValue !== undefined ?
				 instValue.toLocaleString("de-DE", { style: 'currency', currency: 'EUR' }) : undefined

 $: interest = finalResult ? finalResult.interest : undefined
 $: interestGerman = interest !== undefined ? interest.toLocaleString("de-DE") : undefined

 $: plan = finalResult?.success ? adeltaApplyRazPlan(finalResult) : undefined
 
 $: firstInterest = finalResult?.firstInst ? new Date(finalResult.firstInst) : undefined
 $: firstInterestLabel = firstInterest ? firstInterest.toLocaleDateString("de-DE", {year: 'numeric', month: '2-digit', day: '2-digit'}) : undefined
 
 $: lastInterest = plan? new Date(plan.plan[plan.plan.length-1].dueDate) : undefined
 $: lastInterestLabel = lastInterest ? lastInterest.toLocaleDateString("de-DE", {year: 'numeric', month: '2-digit', day: '2-digit'}) : undefined
 
 $: feeAmount = finalResult?.fee?.amount
 $: feeDue =finalResult?.fee ? new Date(finalResult.fee.due) : undefined
 $: feeDueLabel = feeDue ? feeDue.toLocaleDateString("de-DE", {year: 'numeric', month: '2-digit', day: '2-digit'}) : undefined

 onMount(() => {
	 if (window.adeltaRoot) {
		 imagePath = `${window.adeltaRoot}/images`
	 }
 })

 function send($event) {
	 if (forbidSend) {
		 checkForInvalid = true
	 }
	 else {
		 const s = JSON.parse(JSON.stringify(state))
		 s.debFname = debFname
		 s.debLname = debLname
		 s.debEmail = debEmail
		 s.debStreet = debStreet
		 s.debPlz = debPlz
		 s.debCity = debCity
		 s.debPhone = debPhone
		 if (cusName) {
			 s.cusName = cusName
		 } else {
			 delete s.cusName
		 }
		 if (cusLoc) {
			 s.cusLoc = cusLoc
		 } else {
			 delete s.cusLoc
		 }
		 isBusy = true
		 const start = new Date().valueOf()
		 const sendResult = fs.send(s)
		 const end = new Date().valueOf()
		 if (end - start < 1000) {
			 window.setTimeout(() => {
				 isBusy = false
				 gotoNext()
			 }, 1000)
		 }
		 else {
			 isBusy = false
			 gotoNext()
		 }
	 }
 }

 function focusMe(node, isError) {
	 return {
		 update(isError) {
			 if (hasEmpty) {
				 const id = node.getAttribute('id')
				 if (id === unfilled[0].name) {
					 node.focus()
				 }
			 }
		 }
	 }
 }

 function updateState() {
	 const s = JSON.parse(JSON.stringify(state))
	 return s
 }

 function gotoNext($event) {
	window.dataLayer.push({
 	   'event': 'form_ratenrechner',
    	'step': 'form_sent'
	});
	 dispatch("next", updateState())
 }

 function gotoPrev($event) {
 	 dispatch("prev", updateState())
 }
</script>
{#if isBusy}
	<div class="busy absolute w-full h-full bg-white opacity-80 flex items-center justify-center">
		<div class="animate-spin w-16 h-16 rounded-full border-t-2 border-b-2 border-brand"></div>
	</div>
{/if}
{#if success}
	<div class="results grid grid-cols-[auto,1fr] gap-x-4 gap-y-2">
		<h3 class="font-extrabold col-span-2 text-xl text-brand">Unser Ratenzahlungs-Angebot</h3>
		{#if overdue}
			<div class="font-light text-brand mb-2 col-span-2">
				Die Konditionen der Ratenzahlung können im Angebot von den hier gezeigten abweichen, insbesondere können bei eingetretener Überfälligkeit der Forderung zusätzliche Gebühren anfallen.
			</div>
		{/if}
		{#if areMonthsChanged }
			<div class="font-light text-brand mb-2 col-span-2">
				Die von Ihnen gewählte Laufzeit der Ratenzahlung kann bei endfälligen Rechnungen nicht gewährt werden. Ihre Anfrage wurde daher auf eine Laufzeit von { firstWorkingMonth } Monaten umgestellt.
			</div>
		{/if}

		<span class="">Monatliche Rate</span>
		{#if finalResult}
			<span class="font-bold">{ rateGerman }</span>
		{:else}
			<span class="block w-full bg-gray-200 animate-pulse"></span>
		{/if}
		<span class="">Erste Rate fällig am </span>
		{#if finalResult}
			<span class="font-bold">{ firstInterestLabel }</span>
		{:else}
			<span class="block w-full bg-gray-200 animate-pulse"></span>
		{/if}
		<span class="">Letzte Rate fällig am</span>
		{#if finalResult}
			<span class="font-bold">{ lastInterestLabel }</span>
		{:else}
			<span class="block w-full bg-gray-200 animate-pulse"></span>
		{/if}
		<span class="">Zinssatz</span>
		{#if finalResult}
			<span class="font-bold">{ interestGerman }% p.a.</span>
		{:else}
			<span class="block w-full bg-gray-200 animate-pulse"></span>
		{/if}
	</div>
{:else}
	<div class="results flex flex-col">
		<h3 class="text-2xl text-brand font-extrabold">Wir können Ihnen leider keinen Finanzierungs-Vorschlag machen</h3>
		<div class="mt-4">
			{#each errors as error}
				<p class="italic">{ error }</p>
			{/each}
		</div>
	</div>
{/if}
<div class="line h-px bg-gray-400 my-8"></div>
<div class="contact flex flex-col flex-nowrap gap-y-4">
	{#if success}
		<div class="text-xl text-brand font-extrabold text-brand">Vertragsunterlagen anfordern</div>
	{:else}
		<div class="text-xl text-brand font-extrabold text-brand">Ihre Kontaktdaten</div>
		<div class="text-brand font-light mt-2 mb-4">Bitte senden Sie uns Ihre Kontaktinformationen, wir setzen uns umgehend mit Ihnen in Verbindung.</div>
	{/if}
	<div class="w-full">Bitte füllen Sie die Felder aus</div>
	<div class="flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-4">
		<label for="debFname" class="sm:row-start-1 ext-gray-800">Vorname</label>
		<input type="text"
			   id="debFname"
			   placeholder="Vorname"
			   use:focusMe={ isError }
			   bind:value={ debFname }
			   class="font-sans sm:row-start-2 select-all block rounded mt-1 w-full h-12 p-6 border border-l-[3px] outline-2 border-gray-200
					 { isFnameError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
					 { isPristine ? 'border-l-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
					 { isFnameCorrect ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }">

		<label for="debLname" class="mt-4 sm:mt-0 text-gray-800 sm:row-start-1 t">Name</label>
		<input type="text"
			   id="debLname"
			   placeholder="Name"
			   use:focusMe={ isError }
			   class="font-sans block rounded mt-1 w-full h-12 p-6 border border-l-[3px] sm:col-start-2 outline-2 border-gray-200
					 { isLnameError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
					 { isPristine ? 'border-l-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
					 { isLnameCorrect ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
			   bind:value={ debLname } />
	</div>
	<div class="flex flex-col">
		<label for="debStreet" class="sm:row-start-1 text-gray-800">Straße und Hausnummer</label>
		<input type="text" 
			   id="debStreet"
			   placeholder="Straße und Hausnummer"
			   use:focusMe={ isError }
			   class="sm:row-start-2  block rounded mt-1 w-full h-12 p-6 border border-l-[3px] border-gray-200 outline-2
					 { isStreetError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
					 { isPristine ? 'border-l-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
					 { isStreetCorrect ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
					 bind:value={ debStreet } />
	</div>
	<div class="flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-4">
		<label for="debPlz" class="sm:row-start-1 text-gray-800">PLZ</label>
		<input type="text"
			   id="debPlz"
			   placeholder="PLZ"
			   use:focusMe={ isError }
			   class="font-sans sm:row-start-2 block rounded mt-1 w-full h-12 p-6 border border-l-[3px] border-gray-200 outline-2
					 { isPlzError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
					 { isPristine ? 'border-l-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
					 { isPlzCorrect ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
					 bind:value={ debPlz } />
		
		<label for="debCity" class="mt-4 sm:mt-0 sm:row-start-1 text-gray-800">Ort</label>
		<input type="text" id="debCity" placeholder="Ort"
			   use:focusMe={ isError }
			   class="font-sans sm:row-start-2 sm:cal-start-2 block rounded mt-1 w-full h-12 p-6 border border-l-[3px] border-gray-200 outline-2
					 { isCityError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
					 { isPristine ? 'border-l-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
					 { isCityCorrect ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
			   bind:value={ debCity } />
	</div>
	<div class="flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-4">
		<label for="debPhone" class="text-gray-800 sm:col-start-1 row-start-1">Telefon</label>
		<input type="text" 
			   use:focusMe={ isError }
			   id="debPhone"
			   placeholder="Telefon"
			   class="sm:row-start-2 select-all block rounded mt-1 w-full h-12 p-6 border border-l-[3px] outline-2
					 { isPhoneError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
					 { isPristine ? 'border-l-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
					 { isPhoneCorrect ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
		       bind:value={ debPhone } />
		<label for="debEmail" class="mt-4 sm:mt-0 sm:col-start-2 text-gray-800">E-Mail-Adresse</label>
		<input type="text" 
			   use:focusMe={ isError }
			   id="debEmail" placeholder="E-Mail-Adresse"
			   class="font-sans sm:col-start-2 sm:row-start-2 select-all block rounded mt-1 w-full h-12 p-6 border border-l-[3px] border-gray-200 outline-2
					 { isEmailError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
					 { isPristine ? 'border-l-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
					 { isEmailCorrect ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
			   bind:value={ debEmail } />
	</div>
	{#if needsCustomer}
		<label for="cusName" class="text-gray-800">Rechnung ausgestellt durch</label>
		<input type="text"  id="cusName" placeholder="Firma"
			   use:focusMe={ isError }
			   class="font-sans select-all block rounded mt-1 w-full h-12 p-6 border border-l-[3px] border-gray-200 outline-2
					 { isCusNameError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
					 { isPristine ? 'border-l-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
					 { isCusNameCorrect ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
			   bind:value={ cusName } />
		<label for="cusLoc" class="text-gray-800 ">Ort des Rechnungs&shy;austellers</label>
		<input type="text" id="cusLoc" placeholder="PLZ, Ort"
			   use:focusMe={ isError }
			   class="font-sans select-all block rounded mt-1 w-full h-12 p-6 border border-l-[3px] outline-2 border-gray-200 
					 { isCusLocError ? 'border-l-red-500 active:border-l-red-500 placeholder-red-400 focus:outline-red-500' : '' }
					 { isPristine ? 'border-l-gray-200 active:border-l-gray-400 active:placeholder-gray-200 focus:outline-brand' : '' }
					 { isCusLocCorrect ? 'border-l-lime-600 active:border-l-lime-600 active:placeholder-gray-200 focus:outline-brand' : '' }"
			   bind:value={ cusLoc } />
	{/if}
	<div class="">
		Die Daten, die Sie in den Ratenrechner eingegebenen haben, werden durch den Klick auf den Absenden-Button an { external } die ADELTA.FINANZ AG, Marc-Chagall-Straße 2, 40477 Düsseldorf, zur weiteren Bearbeitung Ihres Ratenzahlungswunsches übermittelt.<br /> <a class=" underline" href="https://www.adeltafinanz.com/ueber-adelta/datenschutzerklaerung/" target="_blank">Die Datenschutzerklärung der ADELTA.FINANZ AG finden Sie hier.</a>
	</div>
</div>
<div class="flex flex-row justify-center gap-x-2">
	<a href="#prev"
	   class="font-medium inline-block mt-8 px-8 py-2 rounded bg-white text-brand no-underline border border-brand hover:-translate-y-px hover:drop-shadow" on:click|preventDefault={ gotoPrev }>
		Zurück</a>
	<input type="submit"
		   class="inline-block mt-8 px-8 py-2 rounded bg-brand text-white no-underline font-bold transition-all
				 translate-y-0
				 cursor-pointer
				 hover:-translate-y-px hover:drop-shadow hover:text-white"
		   on:click|preventDefault={ send }
		   value="Absenden" />
</div>
